import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeConsulting from "../sections/home-consulting"

const EnterpriseIotAssistance = () => (
  <Layout>
    <SEO title="Enterprise IOT Assistance" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_07">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>Enterprise IOT Assistance</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#">Enterprise IOT Assistance</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>IOT Assistance</h2>
          <p>
            Globally the enterprise IOT division face the challenges of
            selecting technologies of diverse maturity level.The roaming
            solutions are provided by multi vendors right from IOT modules to
            sim cards to connectivity and the roaming capabilities. The
            solutions have been able to cost effectively tailor all these
            together. NGNAPPS specialized LAB provides one stop shop to
            enterprise for certifying their solution before the handover these
            modules to their end customers, which enable them to perform
            Connectivity test,SIM card testing,E Call Test,B Call Test.
          </p>
        </div>
      </div>
    </section>
    <HomeConsulting />
  </Layout>
)

export default EnterpriseIotAssistance
